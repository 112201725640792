import { interactionEnded, interactionStarted, log } from './monitoring';

export const getInteractionName = (methodName: string) => 'utils.timeoutMonitor.' + methodName;

export const getLoggerString = (methodName: string, stage: string) => `Timeout monitor: ${methodName} - ${stage}`;

// Monitoring SDK calls which never resolve and hangs the installation
export async function withTimeoutMonitor<T>(
  methodName: string,
  time: number,
  promise: () => T,
  sentryPayload = {} as any,
): Promise<T> {
  const interactionName = getInteractionName(methodName);
  interactionStarted(interactionName);

  return new Promise(async (res, rej) => {
    let hasTimeouted = false;

    log(getLoggerString(methodName, 'Invocation'));

    const timeout = setTimeout(() => {
      log(getLoggerString(methodName, 'Timeout'), sentryPayload);
      console.log('Members Area: Timeout of promise ' + methodName);
      hasTimeouted = true;
    }, time);

    try {
      const response = await promise();

      if (hasTimeouted) {
        log(getLoggerString(methodName, 'Resolved'), sentryPayload);
      }

      clearInterval(timeout);
      interactionEnded(interactionName);
      res(response);
    } catch (error) {
      if (hasTimeouted) {
        log(getLoggerString(methodName, 'Rejected'), {
          ...sentryPayload,
          extra: {
            ...(sentryPayload?.extra || {}),
            error,
          },
        });
      }

      clearInterval(timeout);
      interactionEnded(interactionName);
      rej(error);
    }
  });
}
